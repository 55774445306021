import { theme } from "@product/scmp-sdk";
import type { ComponentProps, FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import type { Props as AdSlotProps } from "scmp-app/components/advertisement/ad-slots/ad-slot";
import { TargetingPaidType } from "scmp-app/components/advertisement/ad-slots/types";
import {
  SectionTopWidgetOne,
  SectionTopWidgetThree,
  SectionTopWidgetTwo,
} from "scmp-app/components/post-magazine/section-top-widget";
import type { sectionTopPostMagazineQuery$key } from "scmp-app/queries/__generated__/sectionTopPostMagazineQuery.graphql";

import {
  Subsection1,
  Subsection2,
  Subsection3,
  Subsection4,
  Subsection5,
  Subsection6,
} from "./styles";

type Props = {
  adZone?: string;
  className?: string;
  reference: sectionTopPostMagazineQuery$key;
  sectionId: string;
};

type WidgetMap =
  | {
      component: typeof SectionTopWidgetOne;
      props?: Partial<ComponentProps<typeof SectionTopWidgetOne>>;
    }
  | {
      component: typeof SectionTopWidgetTwo;
      props?: Partial<ComponentProps<typeof SectionTopWidgetTwo>>;
    }
  | {
      component: typeof SectionTopWidgetThree;
      props?: Partial<ComponentProps<typeof SectionTopWidgetThree>>;
    };

export const PostMagazineSectionTop: FunctionComponent<Props> = ({
  adZone = "default",
  reference: reference_,
  sectionId,
}) => {
  const data = useFragment(
    graphql`
      fragment sectionTopPostMagazineQuery on Query {
        sectionTopPostMagazine: section(filter: { entityId: "71" }) {
          subSections {
            entityId
            items(first: 5) {
              edges {
                node {
                  ... on Section {
                    topStories {
                      items(first: 10) {
                        edges {
                          ...onePostMagSectionTopWidgetArticles
                          ...twoPostMagSectionTopWidgetArticles
                          ...threePostMagSectionTopWidgetArticles
                        }
                      }
                    }
                    entityId
                    ...onePostMagSectionTopWidgetSection
                    ...twoPostMagSectionTopWidgetSection
                    ...threePostMagSectionTopWidgetSection
                  }
                }
              }
            }
          }
        }
      }
    `,
    reference_,
  );

  const subSectionComponents = [
    Subsection1,
    Subsection2,
    Subsection3,
    Subsection4,
    Subsection5,
    Subsection6,
  ];

  const defaultAdSlotProps: Partial<AdSlotProps> = {
    targeting: {
      paid: TargetingPaidType.Free,
      scsid: [sectionId],
    },
    withBackground: true,
    withLabel: true,
    withSticky: true,
    zone: adZone,
  };

  const widgetsMap: { [key: number]: WidgetMap } = {
    0: {
      component: SectionTopWidgetOne,
      props: {
        adsConfigs: [
          {
            ...defaultAdSlotProps,
            adUnit: "d_lrec2",
            breakpoint: theme.breakpoints.up("tablet"),
            sizes: [[300, 250], "fluid"],
          },
          {
            ...defaultAdSlotProps,
            adUnit: "m_lrec1",
            breakpoint: theme.breakpoints.only("mobile"),
            sizes: [[300, 250], "fluid"],
          },
        ],
      },
    },
    1: {
      component: SectionTopWidgetOne,
      props: { showLeadStory: true },
    },
    2: {
      component: SectionTopWidgetTwo,
    },
    3: {
      component: SectionTopWidgetOne,
      props: {
        adsConfigs: [
          {
            ...defaultAdSlotProps,
            adUnit: "d_lrec3",
            breakpoint: theme.breakpoints.up("tablet"),
            sizes: [[300, 250], "fluid"],
          },
          {
            ...defaultAdSlotProps,
            adUnit: "m_banner4",
            breakpoint: theme.breakpoints.only("mobile"),
            sizes: [[300, 250], "fluid"],
          },
        ],
      },
    },
    4: {
      component: SectionTopWidgetThree,
    },
  };

  const subSections = data.sectionTopPostMagazine?.subSections?.items?.edges ?? [];
  return (
    <>
      {subSections.map(({ node }, index) => {
        const SubSectionComponent = subSectionComponents[index];
        const SectionTopWidget = widgetsMap[index % Object.keys(widgetsMap).length];
        const { component: SectionTopComponent, props: ComponentProps } = SectionTopWidget;

        return (
          <SubSectionComponent key={node.entityId}>
            <SectionTopComponent
              {...ComponentProps}
              reference={node.topStories?.items?.edges ?? []}
              sectionReference={node}
            />
          </SubSectionComponent>
        );
      })}
    </>
  );
};

PostMagazineSectionTop.displayName = "PostMagazineSectionTop";

import styled from "@emotion/styled";

import { AdSlotContainer } from "scmp-app/components/app-bar/styles";
import { setFullViewportWidthStyles } from "scmp-app/lib/styles";

export const LeadStory = styled.div`
  grid-area: lead-story;

  margin-block-start: 20px;

  ${props => props.theme.breakpoints.up("tablet")} {
    margin-block-start: 32px;
  }
  ${props => props.theme.breakpoints.up("homeDesktop")} {
    margin-block-start: 40px;
  }
`;

export const Header = styled.div`
  grid-area: header;
`;

export const Container = styled.div`
  ${setFullViewportWidthStyles}
`;

export const Wrapper = styled.div`
  display: grid;
  grid:
    "top-ads" min-content
    "header" min-content
    "lead-story" min-content
    "." 40px
    "subsection-1" min-content
    "." 40px
    "subsection-2" min-content
    "third-ads" min-content
    "." 40px
    "subsection-3" min-content
    "." 40px
    "recommended" min-content
    "." 40px
    "subsection-4" min-content
    "." 40px
    "subsection-5" min-content
    / minmax(0, 1280px);

  max-inline-size: 1280px;
  margin-inline: auto;

  ${props => props.theme.breakpoints.up("tablet")} {
    grid:
      "top-ads" min-content
      "header" min-content
      "lead-story" min-content
      "." 48px
      "subsection-1" min-content
      "." 48px
      "subsection-2" min-content
      "." 48px
      "third-ads" min-content
      "." 48px
      "subsection-3" min-content
      "." 48px
      "recommended" min-content
      "." 48px
      "subsection-4" min-content
      "." 48px
      "subsection-5" min-content
      / minmax(0, 1280px);
  }

  ${props => props.theme.breakpoints.up("homeDesktop")} {
    grid:
      "top-ads" min-content
      "header" min-content
      "lead-story" min-content
      "." 40px
      "subsection-1" min-content
      "." 80px
      "subsection-2" min-content
      "." 80px
      "third-ads" min-content
      "." 80px
      "subsection-3" min-content
      "." 80px
      "recommended" min-content
      "." 80px
      "subsection-4" min-content
      "." 80px
      "subsection-5" min-content
      / minmax(0, 1280px);
  }
`;

export const StyledAdSlotContainer = styled(AdSlotContainer)`
  grid-area: top-ads;
`;

export const Recommended = styled.div`
  grid-area: recommended;
`;

const Subsection = styled.div``;

export const Subsection1 = styled(Subsection)`
  grid-area: subsection-1;
`;

export const Subsection2 = styled(Subsection)`
  grid-area: subsection-2;
`;

export const Subsection3 = styled(Subsection)`
  grid-area: subsection-3;
`;

export const Subsection4 = styled(Subsection)`
  grid-area: subsection-4;
`;

export const Subsection5 = styled(Subsection)`
  grid-area: subsection-5;
`;

export const ThirdAds = styled.div`
  grid-area: third-ads;

  ${props => props.theme.breakpoints.up("tablet")} {
    margin-inline: 40px;
    padding-block-start: 48px;

    border-block-start: 1px solid #777777;
  }

  ${props => props.theme.breakpoints.up("homeDesktop")} {
    padding-block-start: 80px;
  }
`;

export const AdContainer = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;

  overflow-x: hidden;
`;

import { section as sectionData } from "scmp-app/data/section";

import type { SectionWidget } from "./types";

export const getIncludeSectionFeatureMap = (entityId: string) => {
  const widgets = getSectionWidgets(entityId);
  return {
    includeAsia: widgets.includes("asia"),
    includeComment: widgets.includes("comment"),
    includeMostPopular: widgets.includes("most-popular"),
    includeMultimedia: widgets.includes("multimedia"),
    includePlus: widgets.includes("plus"),
    includeThisWeekInAsia: widgets.includes("this-week-in-asia"),
    includeTrendingTopics: widgets.includes("trending-topics"),
  };
};

export const getSectionWidgets = (entityId: string): SectionWidget[] => {
  switch (entityId) {
    case sectionData.business.entityId:
    case sectionData.china.entityId:
    case sectionData.economy.entityId:
    case sectionData.hongKong.entityId:
      return [
        "plus",
        "comment",
        "recommended-for-you",
        "multimedia",
        "trending-topics",
        "most-popular",
      ];
    case sectionData.asia.entityId:
      return [
        "this-week-in-asia",
        "comment",
        "recommended-for-you",
        "multimedia",
        "trending-topics",
        "most-popular",
      ];
    case sectionData.thisWeekInAsia.entityId:
      return [
        "asia",
        "comment",
        "recommended-for-you",
        "multimedia",
        "trending-topics",
        "most-popular",
      ];
    case sectionData.comment.entityId:
    case sectionData.kPop.entityId:
    case sectionData.news.peopleAndCulture.entityId:
    case sectionData.sport.entityId:
    case sectionData.tech.entityId:
      return ["recommended-for-you", "comment", "multimedia", "trending-topics"];
    case sectionData.lifestyle.entityId:
    case sectionData.world.entityId:
      return ["comment", "recommended-for-you", "multimedia", "trending-topics", "most-popular"];
    case sectionData.olympic.entityId:
      return ["recommended-for-you", "multimedia", "most-popular"];
    default:
      return ["comment", "recommended-for-you", "multimedia", "trending-topics", "most-popular"];
  }
};

import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import type { Props as AdSlotProps } from "scmp-app/components/advertisement/ad-slots/ad-slot";
import { AdSlot } from "scmp-app/components/advertisement/ad-slots/ad-slot";
import type { onePostMagSectionTopWidgetArticles$key } from "scmp-app/queries/__generated__/onePostMagSectionTopWidgetArticles.graphql";
import type { onePostMagSectionTopWidgetSection$key } from "scmp-app/queries/__generated__/onePostMagSectionTopWidgetSection.graphql";

import {
  AdsContainer,
  Container,
  Description,
  Divider,
  SectionInfoWrapper,
  SectionName,
  StyledContentItemPostMagazinePrimary,
} from "./styles";

export type Props = {
  adsConfigs?: [AdSlotProps, AdSlotProps];
  className?: string;
  reference: onePostMagSectionTopWidgetArticles$key;
  sectionReference: onePostMagSectionTopWidgetSection$key;
  showLeadStory?: boolean;
};
export const SectionTopWidgetOne: FunctionComponent<Props> = ({
  adsConfigs,
  className,
  reference: reference_,
  sectionReference,
  showLeadStory = false,
}) => {
  const section = useFragment(
    graphql`
      fragment onePostMagSectionTopWidgetSection on Section {
        name
        description {
          text
        }
      }
    `,
    sectionReference ?? null,
  );

  const data = useFragment(
    graphql`
      fragment onePostMagSectionTopWidgetArticles on QueueItemsEdge @relay(plural: true) {
        node {
          ... on Content {
            ...postMagazinePrimaryContent
          }
        }
      }
    `,
    reference_,
  );

  const articles = data.slice(0, showLeadStory ? 3 : 4);

  return (
    <Container $showLeadStory={showLeadStory} className={className}>
      {articles.map((edge, index) => {
        const showMobileSquareImage = showLeadStory && index === 0;
        return (
          <StyledContentItemPostMagazinePrimary
            $showMobileSquareImage={showMobileSquareImage}
            imageResponsiveVariants={{
              desktopUp: "size1200x800",
              mobileUp: showMobileSquareImage ? "size768x768" : "size540x360",
              tabletUp: "size1200x800",
            }}
            key={index}
            reference={edge.node}
            topicLinkVariant={{ type: "main" }}
          />
        );
      })}
      <SectionInfoWrapper>
        <SectionName>{section.name}</SectionName>
        <Description>{section.description?.text}</Description>
      </SectionInfoWrapper>
      <Divider />
      {adsConfigs && (
        <AdsContainer>
          <AdSlot {...adsConfigs[0]} />
          <AdSlot {...adsConfigs[1]} />
        </AdsContainer>
      )}
    </Container>
  );
};

SectionTopWidgetOne.displayName = "SectionTopWidgetOne";

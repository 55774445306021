import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontIndivisibleVariable } from "@product/scmp-sdk";

import { ContentItemPostMagazinePrimary } from "scmp-app/components/content/content-item-render/variants/post-magazine-primary";
import {
  CoverImage,
  StyledCoverEntityLink,
  StyledEntityLink,
} from "scmp-app/components/content/content-item-render/variants/post-magazine-primary/styles";

export type SectionTopWidgetOneProps = {
  $showLeadStory?: boolean;
};

export type StyledContentItemPostMagazinePrimaryProps = {
  $showMobileSquareImage?: boolean;
};

export const SectionInfoWrapper = styled.div`
  grid-area: section-info;

  ${props => props.theme.breakpoints.between("mobile", "homeDesktop")} {
    display: flex;
    justify-content: space-between;
  }
`;

export const SectionName = styled.div`
  font-size: 32px;
  font-family: ${fontIndivisibleVariable};
  font-style: normal;
  font-variation-settings: "wght" 300;

  line-height: 110%;
  letter-spacing: -1.6px;
  ${props => props.theme.breakpoints.up("homeDesktop")} {
    font-size: 48px;
    font-style: normal;
    letter-spacing: -2.4px;
  }
`;

export const Description = styled.div`
  display: none;

  font-size: 14px;
  font-family: ${fontIndivisibleVariable};
  font-style: normal;
  line-height: 20px;
  letter-spacing: -0.28px;
  font-variant-numeric: lining-nums proportional-nums;
  font-variation-settings: "wght" 500;
  ${props => props.theme.breakpoints.up("tablet")} {
    display: block;
  }
  ${props => props.theme.breakpoints.between("tablet", "homeDesktop")} {
    inline-size: 276px;
    padding-inline-start: 24px;
    border-inline-start: 1px solid #777777;

    box-sizing: content-box;
  }
  ${props => props.theme.breakpoints.up("homeDesktop")} {
    margin-block-start: 40px;

    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.36px;
  }
`;

export const AdsContainer = styled.div`
  grid-area: ad-slot;

  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;

  overflow-x: hidden;
`;

export const Divider = styled.div`
  grid-area: divider;

  display: none;

  inline-size: 1px;
  block-size: 100%;

  background-color: #777777;
  ${props => props.theme.breakpoints.up("homeDesktop")} {
    display: block;
  }
`;

export const StyledContentItemPostMagazinePrimary = styled(
  ContentItemPostMagazinePrimary,
)<StyledContentItemPostMagazinePrimaryProps>`
  ${props =>
    props.$showMobileSquareImage &&
    css`
      ${props.theme.breakpoints.only("mobile")} {
        ${StyledCoverEntityLink}, ${CoverImage} {
          aspect-ratio: 1;
        }
      }
    `}
  ${StyledEntityLink} {
    font-size: 24px;
    letter-spacing: -0.72px;

    ${props => props.theme.breakpoints.up("homeDesktop")} {
      font-size: 32px;
      letter-spacing: -0.96px;
    }
  }
`;

export const Container = styled.div<SectionTopWidgetOneProps>`
  display: grid;
  ${props =>
    props.$showLeadStory
      ? css`
          grid:
            "section-info" min-content
            "first-article" min-content
            "second-article" min-content
            "third-article" min-content
            "fourth-article" min-content
            / 1fr;
        `
      : css`
          grid:
            "section-info" min-content
            "first-article" min-content
            "second-article" min-content
            "third-article" min-content
            "fourth-article" min-content
            "ad-slot" min-content
            / 1fr;
        `}

  gap: 40px;

  margin-inline: 20px;
  padding-block-start: 40px;

  border-block-start: 1px solid #777777;
  ${props => props.theme.breakpoints.up("tablet")} {
    margin-inline: 40px;
  }

  ${StyledContentItemPostMagazinePrimary} {
    &:nth-child(1) {
      grid-area: first-article;
    }
    &:nth-child(2) {
      grid-area: second-article;
    }
    &:nth-child(3) {
      grid-area: third-article;
    }
    &:nth-child(4) {
      grid-area: fourth-article;
    }
  }
  ${props => props.theme.breakpoints.up("tablet")} {
    ${props =>
      props.$showLeadStory
        ? css`
            grid:
              "section-info section-info" min-content
              "first-article first-article" min-content
              "second-article third-article" min-content
              "ad-slot ad-slot" min-content
              / 1fr 1fr;
          `
        : css`
            grid:
              "section-info section-info" min-content
              "first-article second-article" min-content
              "third-article fourth-article" min-content
              "ad-slot ad-slot" min-content
              / 1fr 1fr;
          `};
  }

  ${props => props.theme.breakpoints.up("homeDesktop")} {
    ${props =>
      props.$showLeadStory
        ? css`
            grid:
              "section-info divider first-article first-article" min-content
              "ad-slot divider second-article third-article" min-content / 300px 1px minmax(
                0,
                390px
              )
              minmax(0, 390px);
          `
        : css`
            grid:
              "section-info divider first-article second-article" min-content
              "ad-slot divider third-article fourth-article" min-content / 300px 1px minmax(
                0,
                390px
              )
              minmax(0, 390px);
          `}
    ${AdsContainer} {
      align-self: self-end;
    }
  }
`;

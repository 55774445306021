import styled from "@emotion/styled";
import { fontIndivisibleVariable } from "@product/scmp-sdk";

import { Container as FourContainer } from "scmp-app/components/content/content-item-render/variants/post-magazine-section-widget-three/styles";
import { setFullViewportWidthStyles } from "scmp-app/lib/styles";

export const Container = styled.div`
  background: #d6cab0;
  ${setFullViewportWidthStyles}
`;
export const Wrapper = styled.div`
  max-inline-size: 1280px;
  margin-inline: auto;
  padding-block: 56px;
  padding-inline: 20px;

  ${props => props.theme.breakpoints.up("tablet")} {
    padding-inline: 40px;
  }

  ${props => props.theme.breakpoints.up("homeDesktop")} {
    padding-block: 80px;
  }
`;

export const Title = styled.div`
  margin-block-end: 10px;

  color: #111111;
  font-weight: 300;
  font-size: 48px;
  font-family: ${fontIndivisibleVariable};
  line-height: 110%;
  letter-spacing: -2.4px;
  text-align: center;
  text-transform: uppercase;
  font-variation-settings: "wght" 300;

  ${props => props.theme.breakpoints.up("tablet")} {
    font-size: 48px;
  }
  ${props => props.theme.breakpoints.up("homeDesktop")} {
    font-size: 56px;
    letter-spacing: -2.8px;
  }
`;

export const Description = styled.div`
  inline-size: 100%;
  max-inline-size: 375px;
  margin-block-end: 49px;
  margin-inline: auto;

  color: #111111;
  font-weight: 500;
  font-size: 14px;
  font-family: ${fontIndivisibleVariable};
  line-height: 20px;
  letter-spacing: -0.28px;
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-variation-settings: "wght" 500;

  ${props => props.theme.breakpoints.up("tablet")} {
    margin-block-end: 48px;
  }

  ${props => props.theme.breakpoints.up("homeDesktop")} {
    max-inline-size: none;
    margin-block-end: 72px;

    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.36px;
  }
`;

export const TopStories = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  ${props => props.theme.breakpoints.up("tablet")} {
    flex-direction: row;
    gap: 32px;
  }

  ${props => props.theme.breakpoints.up("homeDesktop")} {
    gap: 40.5px;
  }

  ${FourContainer} {
    flex: 1;
  }
`;

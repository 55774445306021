import styled from "@emotion/styled";
import { fontIndivisibleVariable } from "@product/scmp-sdk";

import { Flag } from "scmp-app/components/content/content-headline/content-headline-tag/styles";
import { EntityLink } from "scmp-app/components/entity-link";

export const Container = styled.div`
  position: relative;
`;

export const ContentContainer = styled.div`
  position: absolute;
  inset-block-end: 13px;
  inset-inline-start: 50%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  inline-size: 100%;
  padding-inline: 30px;

  transform: translateX(-50%);

  ${props => props.theme.breakpoints.up("tablet")} {
    inset-block-end: 25px;

    padding-inline: 37px;
  }
`;

export const Topic = styled.div`
  > a {
    color: #ffffff;
    font-weight: 900;
    font-size: 12px;
    font-family: ${fontIndivisibleVariable};
    letter-spacing: 1.2px;
    text-align: center;
    text-transform: uppercase;
    font-variation-settings: "wght" 900;

    &:hover > span {
      color: #ffffff;
    }

    ${props => props.theme.breakpoints.up("tablet")} {
      font-size: 16px;
      letter-spacing: 1.6px;
    }
  }
`;

export const StyledEntityLink = styled(EntityLink)`
  display: block;

  margin-block: 8px 14px;

  color: #ffffff;
  font-weight: 300;
  font-size: 20px;
  font-family: ${fontIndivisibleVariable};
  line-height: 22px;
  text-align: center;
  font-variation-settings: "wght" 300;
  ${props => props.theme.breakpoints.up("tablet")} {
    margin-block: 14px 16px;

    font-size: 26px;
    line-height: 34px;
  }
`;

export const Headline = styled.div`
  ${Flag} {
    color: #ffffff;
  }
`;

export const Divider = styled.div`
  inline-size: 87px;
  block-size: 1px;
  margin-block: 14px;

  background-color: #f6f6f6;
`;

export const ReadMore = styled(EntityLink)`
  display: block;

  padding-block: 6px;
  padding-inline: 9px;

  color: #ffffff;
  font-weight: 500;
  font-size: 10.5px;
  font-family: ${fontIndivisibleVariable};
  letter-spacing: 1.05px;
  text-align: center;
  text-transform: uppercase;
  font-variation-settings: "wght" 500;

  ${props => props.theme.breakpoints.up("tablet")} {
    padding-block: 8px;
    padding-inline: 12px;

    font-size: 14px;
    letter-spacing: 1.4px;
  }
`;

export const StyledCoverEntityLink = styled(EntityLink)`
  aspect-ratio: 300/450;

  display: block;
`;

export const CoverImage = styled.div`
  position: relative;
  aspect-ratio: 300/450;

  inline-size: 100%;
  block-size: 100%;

  &::after {
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 0;
    content: "";

    inline-size: 100%;
    block-size: 100%;

    background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 72.22%);

    opacity: 0.8;
  }
`;

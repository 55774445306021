import first from "lodash/first";
import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { EntityLink } from "scmp-app/components/entity-link";
import type { sectionStyleSectionSection$key } from "scmp-app/queries/__generated__/sectionStyleSectionSection.graphql";

import { SectionNameText, StyledBaseImage } from "./styles";

type Props = {
  className?: string;
  reference: sectionStyleSectionSection$key;
};
export const SectionStyleSection: FunctionComponent<Props> = ({
  className,
  reference: reference_,
}) => {
  const section = useFragment(
    graphql`
      fragment sectionStyleSectionSection on Section {
        name
        images(filter: { type: { values: ["logo"] } }) {
          url
        }
        ...entityLink
      }
    `,
    reference_,
  );

  if (!section) return null;
  const imageUrl = first(section.images)?.url;

  return (
    <EntityLink className={className} reference={section}>
      {imageUrl ? (
        <StyledBaseImage
          $aspectRatio="auto"
          $background="none"
          $objectFit="cover"
          alt={section.name}
          src={imageUrl}
          disableLinkingData
          lazyload
        />
      ) : (
        <SectionNameText>{"// " + section.name}</SectionNameText>
      )}
    </EntityLink>
  );
};

SectionStyleSection.displayName = "SectionStyleSection";

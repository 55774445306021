/**
 * @generated SignedSource<<3580f7423093a30ed18a3c761740d166>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type sectionPostMagazineQuery$data = {
  readonly section: {
    readonly advertZone: string | null | undefined;
    readonly entityId: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"headerPostMagazineQuery" | "leadStoryPostMagazineQuery" | "sectionTopPostMagazineQuery">;
  readonly " $fragmentType": "sectionPostMagazineQuery";
};
export type sectionPostMagazineQuery$key = {
  readonly " $data"?: sectionPostMagazineQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"sectionPostMagazineQuery">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "articlesQueueName"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "entityId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "sectionPostMagazineQuery",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "headerPostMagazineQuery"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "sectionTopPostMagazineQuery"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "articlesQueueName",
          "variableName": "articlesQueueName"
        }
      ],
      "kind": "FragmentSpread",
      "name": "leadStoryPostMagazineQuery"
    },
    {
      "alias": null,
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "entityId",
              "variableName": "entityId"
            }
          ],
          "kind": "ObjectValue",
          "name": "filter"
        }
      ],
      "concreteType": "Section",
      "kind": "LinkedField",
      "name": "section",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "entityId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "version",
              "value": 2
            }
          ],
          "kind": "ScalarField",
          "name": "advertZone",
          "storageKey": "advertZone(version:2)"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "2e88288c877e1a529df26acf3de067cd";

export default node;

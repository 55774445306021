import { scmpPlus } from "@product/scmp-sdk";
import { type FunctionComponent, useMemo } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";

import { ContentItemPostMagazineRecommended } from "scmp-app/components/content/content-item-render/variants/post-magazine-recommended";
import type { AiRecommendedForYouResponse, AiSectionResponse } from "scmp-app/lib/ai-engine/types";
import type { recommendedPostMagazineQuery } from "scmp-app/queries/__generated__/recommendedPostMagazineQuery.graphql";

import {
  Container,
  Description,
  Header,
  HeaderContainer,
  StyledSwiper,
  StyledSwiperSlide,
  SwiperContainer,
} from "./styles";
type Props = {
  aiData: AiSectionResponse | AiRecommendedForYouResponse;
  className?: string;
};

export const PostMagazineRecommended: FunctionComponent<Props> = ({ aiData, className }) => {
  const { results: aiArticleResults } = aiData;
  const articleEntityIds = aiArticleResults.map(result => result.id) ?? [];

  const { articles } = useLazyLoadQuery<recommendedPostMagazineQuery>(
    graphql`
      query recommendedPostMagazineQuery(
        $entityIds: [String]
        $first: Int!
        $scmpPlusPaywallTypeIds: [String]
      ) {
        articles(
          exclude: { paywallTypeIds: $scmpPlusPaywallTypeIds }
          filter: { entityIds: $entityIds }
          first: $first
        ) {
          edges {
            node {
              ... on Content {
                entityId
                ...postMagazineRecommendedItemContent
              }
            }
          }
        }
      }
    `,
    {
      entityIds: articleEntityIds,
      first: aiArticleResults.length ?? 0,
      scmpPlusPaywallTypeIds: [scmpPlus.article.paywallTypeId],
    },
    { fetchPolicy: "store-or-network" },
  );

  const sortedArticles = useMemo(
    () => [...aiArticleResults].sort((a, b) => a.rank - b.rank),
    [aiArticleResults],
  );

  const articleMap = useMemo(
    () => Object.fromEntries(articles?.edges?.map(({ node }) => [node.entityId!, node])),
    [articles],
  );

  const articleNodes = useMemo(
    () => sortedArticles.map(result => articleMap[result.id]),
    [articleMap, sortedArticles],
  );

  return (
    <Container className={className}>
      <HeaderContainer>
        <Header>RECOMMENDED</Header>
        <Description>Don’t miss out lorem ipsum dolor sed </Description>
      </HeaderContainer>
      <SwiperContainer>
        <StyledSwiper
          pagination={{
            clickable: true,
          }}
          slidesPerView="auto"
          spaceBetween={24}
        >
          {articleNodes.map(node => (
            <StyledSwiperSlide key={node.entityId}>
              <ContentItemPostMagazineRecommended
                reference={node}
                topicLinkVariant={{ type: "main" }}
              />
            </StyledSwiperSlide>
          ))}
        </StyledSwiper>
      </SwiperContainer>
    </Container>
  );
};

PostMagazineRecommended.displayName = "PostMagazineRecommended";

import styled from "@emotion/styled";
import { fontRobotoCondensed } from "@product/scmp-sdk";

import { ActionBar } from "scmp-app/components/content/content-item-render/variants/home-primary/styles";
import SpotlightLogo from "scmp-app/components/home/spotlight/logo-spotlight.svg";

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;

  margin-block-end: 20px;

  ${props => props.theme.breakpoints.up("tablet")} {
    margin-block-end: 24px;
  }
`;

export const StyledLogo = styled(SpotlightLogo)``;

export const Description = styled.div`
  display: none;

  ${props => props.theme.breakpoints.up("tablet")} {
    display: block;

    margin-inline-start: 24px;

    color: rgba(0, 0, 0, 0.5);
    font-size: 16px;
    font-family: ${fontRobotoCondensed};
    font-style: italic;
    line-height: 19px;
  }
`;

export const TopStoriesComponent = styled.div`
  ${ActionBar} {
    margin-block-end: 0;
  }
`;

/**
 * @generated SignedSource<<45115ce104febeac111bc529b28a5110>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type sectionTopPostMagazineQuery$data = {
  readonly sectionTopPostMagazine: {
    readonly subSections: {
      readonly entityId: string;
      readonly items: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly entityId?: string;
            readonly topStories?: {
              readonly items: {
                readonly edges: ReadonlyArray<{
                  readonly " $fragmentSpreads": FragmentRefs<"onePostMagSectionTopWidgetArticles" | "threePostMagSectionTopWidgetArticles" | "twoPostMagSectionTopWidgetArticles">;
                }>;
              } | null | undefined;
            } | null | undefined;
            readonly " $fragmentSpreads": FragmentRefs<"onePostMagSectionTopWidgetSection" | "threePostMagSectionTopWidgetSection" | "twoPostMagSectionTopWidgetSection">;
          };
        }>;
      } | null | undefined;
    } | null | undefined;
  };
  readonly " $fragmentType": "sectionTopPostMagazineQuery";
};
export type sectionTopPostMagazineQuery$key = {
  readonly " $data"?: sectionTopPostMagazineQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"sectionTopPostMagazineQuery">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "sectionTopPostMagazineQuery",
  "selections": [
    {
      "alias": "sectionTopPostMagazine",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "entityId": "71"
          }
        }
      ],
      "concreteType": "Section",
      "kind": "LinkedField",
      "name": "section",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Queue",
          "kind": "LinkedField",
          "name": "subSections",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "first",
                  "value": 5
                }
              ],
              "concreteType": "QueueItemConnection",
              "kind": "LinkedField",
              "name": "items",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "QueueItemsEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Queue",
                              "kind": "LinkedField",
                              "name": "topStories",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": [
                                    {
                                      "kind": "Literal",
                                      "name": "first",
                                      "value": 10
                                    }
                                  ],
                                  "concreteType": "QueueItemConnection",
                                  "kind": "LinkedField",
                                  "name": "items",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "QueueItemsEdge",
                                      "kind": "LinkedField",
                                      "name": "edges",
                                      "plural": true,
                                      "selections": [
                                        {
                                          "args": null,
                                          "kind": "FragmentSpread",
                                          "name": "onePostMagSectionTopWidgetArticles"
                                        },
                                        {
                                          "args": null,
                                          "kind": "FragmentSpread",
                                          "name": "twoPostMagSectionTopWidgetArticles"
                                        },
                                        {
                                          "args": null,
                                          "kind": "FragmentSpread",
                                          "name": "threePostMagSectionTopWidgetArticles"
                                        }
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": "items(first:10)"
                                }
                              ],
                              "storageKey": null
                            },
                            (v0/*: any*/),
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "onePostMagSectionTopWidgetSection"
                            },
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "twoPostMagSectionTopWidgetSection"
                            },
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "threePostMagSectionTopWidgetSection"
                            }
                          ],
                          "type": "Section",
                          "abstractKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": "items(first:5)"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "section(filter:{\"entityId\":\"71\"})"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "7d2ca99ae198f18d1520e7442ab8f4a6";

export default node;

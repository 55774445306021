/**
 * @generated SignedSource<<28d0390254990181f54073fb58bbea85>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type defaultSectionQuery$data = {
  readonly asiaQueue?: {
    readonly items: {
      readonly edges: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"contentUtilsGetEntityIdsByQueueItemQueueItemsEdge">;
      }>;
    } | null | undefined;
  } | null | undefined;
  readonly commentQueue: {
    readonly items: {
      readonly edges: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"contentUtilsGetEntityIdsByQueueItemQueueItemsEdge">;
      }>;
    } | null | undefined;
  } | null | undefined;
  readonly section: {
    readonly advertZone: string | null | undefined;
    readonly entityId: string;
    readonly name: string;
    readonly subSections: {
      readonly items: {
        readonly edges: ReadonlyArray<{
          readonly " $fragmentSpreads": FragmentRefs<"entityOnelineMenuQueueItemsEdge">;
        }>;
      } | null | undefined;
    } | null | undefined;
  };
  readonly spotlightQueue: {
    readonly items: {
      readonly edges: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"contentUtilsGetEntityIdsByQueueItemQueueItemsEdge">;
      }>;
    } | null | undefined;
  } | null | undefined;
  readonly topStories: {
    readonly items: {
      readonly edges: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"contentUtilsGetEntityIdsByQueueItemQueueItemsEdge" | "oneSectionTopWidgetQueue" | "topStoriesBlockQueueItemsEdge">;
      }>;
    } | null | undefined;
  } | null | undefined;
  readonly twiaQueue?: {
    readonly items: {
      readonly edges: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"contentUtilsGetEntityIdsByQueueItemQueueItemsEdge">;
      }>;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"hooksSectionWidgetQuery" | "sectionNewsletterWidgetQuery" | "sectionSpotlightQuery">;
  readonly " $fragmentType": "defaultSectionQuery";
};
export type defaultSectionQuery$key = {
  readonly " $data"?: defaultSectionQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"defaultSectionQuery">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "applicationId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "commentHarrySectionIds"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "commentQueueLimit"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "commentQueueName"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dailyPulseTypeUuId"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "highlightQueueName"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeAsia"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeComment"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeMostPopular"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeMultimedia"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includePlus"
},
v11 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeThisWeekInAsia"
},
v12 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeTrendingTopics"
},
v13 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "mostPopularQueueName"
},
v14 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "multimediaQueueName"
},
v15 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "plusNewsAgendaEndDate"
},
v16 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "plusNewsAgendaStartDate"
},
v17 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "plusNewsAgendaTypes"
},
v18 = {
  "defaultValue": ([]/*: any*/),
  "kind": "LocalArgument",
  "name": "scmpPlusPaywallTypeIds"
},
v19 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "trendingTopicsQueueName"
},
v20 = [
  {
    "kind": "Variable",
    "name": "entityId",
    "variableName": "entityId"
  }
],
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "version",
      "value": 2
    }
  ],
  "kind": "ScalarField",
  "name": "advertZone",
  "storageKey": "advertZone(version:2)"
},
v23 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "contentUtilsGetEntityIdsByQueueItemQueueItemsEdge"
},
v24 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "QueueItemsEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      (v23/*: any*/)
    ],
    "storageKey": null
  }
],
v25 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "first",
        "value": 5
      }
    ],
    "concreteType": "QueueItemConnection",
    "kind": "LinkedField",
    "name": "items",
    "plural": false,
    "selections": (v24/*: any*/),
    "storageKey": "items(first:5)"
  }
],
v26 = {
  "kind": "Variable",
  "name": "commentHarrySectionIds",
  "variableName": "commentHarrySectionIds"
},
v27 = {
  "kind": "Variable",
  "name": "commentQueueName",
  "variableName": "commentQueueName"
},
v28 = {
  "kind": "Variable",
  "name": "scmpPlusPaywallTypeIds",
  "variableName": "scmpPlusPaywallTypeIds"
},
v29 = {
  "kind": "Variable",
  "name": "mostPopularQueueName",
  "variableName": "mostPopularQueueName"
},
v30 = {
  "kind": "Variable",
  "name": "multimediaQueueName",
  "variableName": "multimediaQueueName"
},
v31 = {
  "kind": "Variable",
  "name": "trendingTopicsQueueName",
  "variableName": "trendingTopicsQueueName"
},
v32 = {
  "kind": "Variable",
  "name": "applicationId",
  "variableName": "applicationId"
},
v33 = {
  "kind": "Variable",
  "name": "dailyPulseTypeUuId",
  "variableName": "dailyPulseTypeUuId"
},
v34 = {
  "kind": "Variable",
  "name": "highlightQueueName",
  "variableName": "highlightQueueName"
},
v35 = {
  "kind": "Variable",
  "name": "plusNewsAgendaEndDate",
  "variableName": "plusNewsAgendaEndDate"
},
v36 = {
  "kind": "Variable",
  "name": "plusNewsAgendaStartDate",
  "variableName": "plusNewsAgendaStartDate"
},
v37 = {
  "kind": "Variable",
  "name": "plusNewsAgendaTypes",
  "variableName": "plusNewsAgendaTypes"
};
return {
  "argumentDefinitions": [
    (v0/*: any*/),
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "articlesQueueName"
    },
    (v1/*: any*/),
    (v2/*: any*/),
    (v3/*: any*/),
    (v4/*: any*/),
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "entityId"
    },
    (v5/*: any*/),
    (v6/*: any*/),
    (v7/*: any*/),
    (v8/*: any*/),
    (v9/*: any*/),
    (v10/*: any*/),
    (v11/*: any*/),
    (v12/*: any*/),
    (v13/*: any*/),
    (v14/*: any*/),
    (v15/*: any*/),
    (v16/*: any*/),
    (v17/*: any*/),
    (v18/*: any*/),
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sectionSpotlightQueueName"
    },
    (v19/*: any*/)
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "defaultSectionQuery",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "fields": (v20/*: any*/),
          "kind": "ObjectValue",
          "name": "filter"
        }
      ],
      "concreteType": "Section",
      "kind": "LinkedField",
      "name": "section",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        (v21/*: any*/),
        (v22/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Queue",
          "kind": "LinkedField",
          "name": "subSections",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "QueueItemConnection",
              "kind": "LinkedField",
              "name": "items",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "QueueItemsEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "entityOnelineMenuQueueItemsEdge"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "topStories",
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "name",
              "variableName": "articlesQueueName"
            }
          ],
          "kind": "ObjectValue",
          "name": "filter"
        }
      ],
      "concreteType": "Queue",
      "kind": "LinkedField",
      "name": "queue",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 17
            }
          ],
          "concreteType": "QueueItemConnection",
          "kind": "LinkedField",
          "name": "items",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "QueueItemsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "oneSectionTopWidgetQueue"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "topStoriesBlockQueueItemsEdge"
                },
                (v23/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": "items(first:17)"
        }
      ],
      "storageKey": null
    },
    {
      "alias": "spotlightQueue",
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "name",
              "variableName": "sectionSpotlightQueueName"
            }
          ],
          "kind": "ObjectValue",
          "name": "filter"
        }
      ],
      "concreteType": "Queue",
      "kind": "LinkedField",
      "name": "queue",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 4
            }
          ],
          "concreteType": "QueueItemConnection",
          "kind": "LinkedField",
          "name": "items",
          "plural": false,
          "selections": (v24/*: any*/),
          "storageKey": "items(first:4)"
        }
      ],
      "storageKey": null
    },
    {
      "alias": "commentQueue",
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "name",
              "variableName": "commentQueueName"
            }
          ],
          "kind": "ObjectValue",
          "name": "filter"
        }
      ],
      "concreteType": "Queue",
      "kind": "LinkedField",
      "name": "queue",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "first",
              "variableName": "commentQueueLimit"
            }
          ],
          "concreteType": "QueueItemConnection",
          "kind": "LinkedField",
          "name": "items",
          "plural": false,
          "selections": (v24/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "condition": "includeAsia",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": "asiaQueue",
          "args": [
            {
              "kind": "Literal",
              "name": "filter",
              "value": {
                "name": "section_top_3"
              }
            }
          ],
          "concreteType": "Queue",
          "kind": "LinkedField",
          "name": "queue",
          "plural": false,
          "selections": (v25/*: any*/),
          "storageKey": "queue(filter:{\"name\":\"section_top_3\"})"
        }
      ]
    },
    {
      "condition": "includeThisWeekInAsia",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": "twiaQueue",
          "args": [
            {
              "kind": "Literal",
              "name": "filter",
              "value": {
                "name": "section_top_323045"
              }
            }
          ],
          "concreteType": "Queue",
          "kind": "LinkedField",
          "name": "queue",
          "plural": false,
          "selections": (v25/*: any*/),
          "storageKey": "queue(filter:{\"name\":\"section_top_323045\"})"
        }
      ]
    },
    {
      "args": (v20/*: any*/),
      "kind": "FragmentSpread",
      "name": "sectionNewsletterWidgetQuery"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "sectionSpotlightQueueName",
          "variableName": "sectionSpotlightQueueName"
        }
      ],
      "kind": "FragmentSpread",
      "name": "sectionSpotlightQuery"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "hooksSectionWidgetQuery",
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "fields": [
                {
                  "kind": "Variable",
                  "name": "entityId",
                  "variableName": "sectionId"
                }
              ],
              "kind": "ObjectValue",
              "name": "filter"
            }
          ],
          "concreteType": "Section",
          "kind": "LinkedField",
          "name": "section",
          "plural": false,
          "selections": [
            (v21/*: any*/),
            (v22/*: any*/)
          ],
          "storageKey": null
        },
        {
          "condition": "includeAsia",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "asiaWidgetQueueQuery"
            }
          ]
        },
        {
          "condition": "includeThisWeekInAsia",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "thisWeekInAsiaWidgetQueue"
            }
          ]
        },
        {
          "condition": "includeComment",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "args": [
                (v26/*: any*/),
                {
                  "kind": "Variable",
                  "name": "commentLimit",
                  "variableName": "commentQueueLimit"
                },
                (v27/*: any*/),
                (v28/*: any*/),
                {
                  "kind": "Literal",
                  "name": "withHarrysView",
                  "value": false
                }
              ],
              "kind": "FragmentSpread",
              "name": "commentQueueQuery"
            }
          ]
        },
        {
          "condition": "includeMostPopular",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "args": [
                (v29/*: any*/)
              ],
              "kind": "FragmentSpread",
              "name": "mostPopularQueueQuery"
            }
          ]
        },
        {
          "condition": "includeMultimedia",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "args": [
                (v30/*: any*/)
              ],
              "kind": "FragmentSpread",
              "name": "multimediaQuery"
            }
          ]
        },
        {
          "condition": "includeTrendingTopics",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "args": [
                (v31/*: any*/)
              ],
              "kind": "FragmentSpread",
              "name": "trendingTopicTopic"
            }
          ]
        },
        {
          "condition": "includePlus",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "args": [
                (v32/*: any*/),
                (v33/*: any*/),
                (v34/*: any*/),
                (v35/*: any*/),
                (v36/*: any*/),
                (v37/*: any*/)
              ],
              "kind": "FragmentSpread",
              "name": "plusWidgetQuery"
            }
          ]
        }
      ],
      "args": [
        (v32/*: any*/),
        (v26/*: any*/),
        {
          "kind": "Variable",
          "name": "commentQueueLimit",
          "variableName": "commentQueueLimit"
        },
        (v27/*: any*/),
        (v33/*: any*/),
        (v34/*: any*/),
        {
          "kind": "Variable",
          "name": "includeAsia",
          "variableName": "includeAsia"
        },
        {
          "kind": "Variable",
          "name": "includeComment",
          "variableName": "includeComment"
        },
        {
          "kind": "Variable",
          "name": "includeMostPopular",
          "variableName": "includeMostPopular"
        },
        {
          "kind": "Variable",
          "name": "includeMultimedia",
          "variableName": "includeMultimedia"
        },
        {
          "kind": "Variable",
          "name": "includePlus",
          "variableName": "includePlus"
        },
        {
          "kind": "Variable",
          "name": "includeThisWeekInAsia",
          "variableName": "includeThisWeekInAsia"
        },
        {
          "kind": "Variable",
          "name": "includeTrendingTopics",
          "variableName": "includeTrendingTopics"
        },
        (v29/*: any*/),
        (v30/*: any*/),
        (v35/*: any*/),
        (v36/*: any*/),
        (v37/*: any*/),
        (v28/*: any*/),
        {
          "kind": "Variable",
          "name": "sectionId",
          "variableName": "entityId"
        },
        (v31/*: any*/)
      ],
      "argumentDefinitions": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/),
        (v7/*: any*/),
        (v8/*: any*/),
        (v9/*: any*/),
        (v10/*: any*/),
        (v11/*: any*/),
        (v12/*: any*/),
        (v13/*: any*/),
        (v14/*: any*/),
        (v15/*: any*/),
        (v16/*: any*/),
        (v17/*: any*/),
        (v18/*: any*/),
        {
          "defaultValue": null,
          "kind": "LocalArgument",
          "name": "sectionId"
        },
        (v19/*: any*/)
      ]
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "27c0650d1707f195b6b376d4921d5e28";

export default node;

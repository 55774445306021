import { notEmpty } from "@product/scmp-sdk";
import { forwardRef } from "react";
import { graphql, useFragment } from "react-relay";

import { withHighlightedHeadline } from "scmp-app/components/content/content-item-render/highlight/hoc";
import { LinkHighlightArea } from "scmp-app/components/content/content-item-render/highlight/styles";
import { useContentItemProviders } from "scmp-app/components/content/content-item-render/hooks";
import type { ContentItemRenderVariantProps } from "scmp-app/components/content/content-item-render/types";
import { getContentSponsor } from "scmp-app/components/content/content-sponsor-tag/helpers";
import { EntityLink } from "scmp-app/components/entity-link";
import type { sectionTwiaContentItemContent$key } from "scmp-app/queries/__generated__/sectionTwiaContentItemContent.graphql";

import {
  ActionBar,
  Container,
  Headline,
  Primary,
  Section,
  StyledContentCoverImage,
  StyledEntityLink,
} from "./styles";

export type Props = {
  reference: sectionTwiaContentItemContent$key;
} & ContentItemRenderVariantProps;

const Component = forwardRef<HTMLDivElement, Props>(
  ({ className, customQueryParameters, onClick, reference: reference_ }, reference) => {
    const content = useFragment(
      graphql`
        fragment sectionTwiaContentItemContent on Content {
          entityId
          ...entityLink
          ...contentCoverImageContent @arguments(withLandscape250x99: true, withSize540x360: true)
          ...helpersUseContentSponsorContent
          ...hooksContentItemProvidersContent
            @arguments(withSections: true, withHeadline: true, withActionBar: true)
        }
      `,
      reference_,
    );
    const providers = useContentItemProviders(content, { type: "main" });

    const contentSponsor = getContentSponsor(content);
    const isSponsorArticle = notEmpty(contentSponsor?.name);

    return (
      <Container className={className} ref={reference}>
        <EntityLink reference={content} onClick={() => onClick?.(content.entityId)}>
          <StyledContentCoverImage
            reference={content}
            responsiveVariants={{
              desktopUp: "size540x360",
              mobileUp: "size540x360",
              tabletUp: "size540x360",
            }}
          />
        </EntityLink>
        <Primary>
          <Section>{providers.sections()}</Section>
          <StyledEntityLink
            query={customQueryParameters}
            reference={content}
            onClick={() => onClick?.(content.entityId)}
          >
            <LinkHighlightArea>
              <Headline>
                {providers.headline({
                  hideTooltip: true,
                  preferSocialHeadline: true,
                })}
              </Headline>
            </LinkHighlightArea>
          </StyledEntityLink>
          {isSponsorArticle ? (
            providers.sponsorTag?.({ variant: "home" })
          ) : (
            <ActionBar>
              {providers.actionBar({
                commentVariant: "reverse",
                isBookmarkVisible: false,
                isCommentsVisible: true,
              })}
            </ActionBar>
          )}
        </Primary>
      </Container>
    );
  },
);

Component.displayName = "ContentItemSectionTwia";

export const ContentItemSectionTwia = withHighlightedHeadline(Component);

/**
 * @generated SignedSource<<d7b99df277c33bf792e9c18dbfb06d0d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type hooksSectionWidgetQuery$data = {
  readonly section: {
    readonly advertZone: string | null | undefined;
    readonly entityId: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"asiaWidgetQueueQuery" | "commentQueueQuery" | "mostPopularQueueQuery" | "multimediaQuery" | "plusWidgetQuery" | "thisWeekInAsiaWidgetQueue" | "trendingTopicTopic">;
  readonly " $fragmentType": "hooksSectionWidgetQuery";
};
export type hooksSectionWidgetQuery$key = {
  readonly " $data"?: hooksSectionWidgetQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"hooksSectionWidgetQuery">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "hooksSectionWidgetQuery"
};

(node as any).hash = "4cc8cb63563374ea6dc42a8fc8cb86b7";

export default node;

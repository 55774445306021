import styled from "@emotion/styled";

import { AdSlot } from "scmp-app/components/advertisement/ad-slots/ad-slot";
import { Container as HeadlineContainer } from "scmp-app/components/content/content-headline/styles";
import { Headline as MagazineStyleCardHeadline } from "scmp-app/components/content/content-item-render/variants/magazines-style-card/styles";
import { Headline as MagazineStyleLeadHeadline } from "scmp-app/components/content/content-item-render/variants/magazines-style-lead/styles";

import { SectionStyleFashionCarousel } from "./section-style-fashion-carousel";
import { SectionStyleMainArticleList } from "./section-style-main-article-list";
import {
  LeadArticle,
  StyledContentItemMagazinesStyleCard,
  StyledContentItemMagazinesStyleCardWide,
  StyledContentItemMagazinesStyleLead,
} from "./section-style-main-article-list/styles";
import { SectionStyleVideoWidget } from "./section-style-video-widget";

export const TopThreeArticleList = styled(SectionStyleMainArticleList)`
  ${LeadArticle} {
    ${StyledContentItemMagazinesStyleLead} {
      ${MagazineStyleLeadHeadline} {
        margin-block-start: 24px;

        ${props => props.theme.breakpoints.up("tablet")} {
          margin-block-start: 14px;
        }

        ${HeadlineContainer} {
          line-height: 34px;

          ${props => props.theme.breakpoints.up("tablet")} {
            font-size: 40px;
            line-height: 48px;
          }

          ${props => props.theme.breakpoints.up("desktop")} {
            font-size: 48px;
            line-height: 56px;
          }
        }
      }
    }
  }

  ${StyledContentItemMagazinesStyleCardWide}, ${StyledContentItemMagazinesStyleCard} {
    ${MagazineStyleCardHeadline} {
      ${HeadlineContainer} {
        font-size: 24px;

        ${props => props.theme.breakpoints.up("tablet")} {
          font-size: 32px;
        }
      }
    }
  }
`;

export const StyledSectionStyleMainArticleList = styled(SectionStyleMainArticleList)`
  ${MagazineStyleCardHeadline} {
    ${HeadlineContainer} {
      ${props => props.theme.breakpoints.down("desktop")} {
        font-size: 24px;
        line-height: 30px;
      }
    }
  }
`;

export const StyledSectionStyleFashionCarousel = styled(SectionStyleFashionCarousel)``;

export const StyledSectionStyleVideoWidget = styled(SectionStyleVideoWidget)`
  margin-block-start: 80px;
`;

export const Divider = styled.hr`
  inline-size: 100%;
  margin-block: 64px 0;

  border: none;
  border-block-end: 1px solid #d8d8d8;

  ${props => props.theme.breakpoints.up("tablet")} {
    margin-block-start: 88px;
  }

  ${props => props.theme.breakpoints.up("desktop")} {
    max-inline-size: 1184px;
    margin-block-start: 168px;
  }
`;

export const BannerAdSlot = styled(AdSlot)`
  flex-direction: column-reverse;

  margin-block-start: 234px;
`;

export const Container = styled.div`
  ${props => props.theme.breakpoints.up("tablet")} {
    margin-block-end: 96px;
  }

  ${props => props.theme.breakpoints.up("desktop")} {
    margin-block-end: 128px;
  }

  ${props => props.theme.breakpoints.up("largeDesktop")} {
    margin-block-end: 168px;
  }
`;

import styled from "@emotion/styled";
import { fontBarlowSemiCondensed } from "@product/scmp-sdk";

import { AuthorName } from "scmp-app/components/author/author-name";
import { Container as HeadlineContainer } from "scmp-app/components/content/content-headline/styles";
import { EntityLink } from "scmp-app/components/entity-link";

export type StyledProps = {
  $isMultipleAuthors?: boolean;
};

export const CoverContainer = styled.div``;

export const StyledEntityLink = styled(EntityLink)`
  color: #222222;
`;

export const Cover = styled.div`
  display: flex;
  justify-content: center;

  inline-size: 100%;
`;
export const Headline = styled.div`
  padding-inline: 20px;

  text-align: center;

  ${HeadlineContainer} {
    ${props => props.theme.breakpoints.up("tablet")} {
      padding-inline: 0;
    }

    ${props => props.theme.breakpoints.up("desktop")} {
      max-inline-size: 800px;
    }
  }
`;
export const StoryLabel = styled.span`
  font-weight: 700;
`;
export const StyledAuthorName = styled(AuthorName)`
  color: #222222;
`;
export const AuthorNameWrapper = styled.span`
  & + & {
    &::before {
      content: "/";

      margin-inline: 4px 4px;

      text-decoration: none;

      pointer-events: none;
    }
  }
`;
export const AuthorNameContainer = styled.div<StyledProps>`
  display: flex;
  flex-direction: ${props => (props.$isMultipleAuthors ? "column" : "row")};
  gap: 4px 6px;
  justify-content: center;

  margin-block-start: 8px;

  font-weight: 400;
  font-size: 14px;
  font-family: ${fontBarlowSemiCondensed};
  line-height: 16.8px;
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: uppercase;

  ${props => props.theme.breakpoints.up("tablet")} {
    font-size: 16px;
    line-height: 19.2px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  place-content: center center;
  align-items: center;

  ${Headline} {
    margin-block: 20px 16px;
  }

  ${props => props.theme.breakpoints.up("tablet")} {
    row-gap: 18px;

    ${Headline} {
      margin-block: 0;
    }
  }
`;

import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { ContentItemPostMagazineSectionWidgetThree } from "scmp-app/components/content/content-item-render/variants/post-magazine-section-widget-three";
import type { threePostMagSectionTopWidgetArticles$key } from "scmp-app/queries/__generated__/threePostMagSectionTopWidgetArticles.graphql";
import type { threePostMagSectionTopWidgetSection$key } from "scmp-app/queries/__generated__/threePostMagSectionTopWidgetSection.graphql";

import { Container, Description, Title, TopStories, Wrapper } from "./styles";

type Props = {
  className?: string;
  reference: threePostMagSectionTopWidgetArticles$key;
  sectionReference: threePostMagSectionTopWidgetSection$key;
};
export const SectionTopWidgetThree: FunctionComponent<Props> = ({
  className,
  reference: reference_,
  sectionReference,
}) => {
  const section = useFragment(
    graphql`
      fragment threePostMagSectionTopWidgetSection on Section {
        name
        description {
          text
        }
      }
    `,
    sectionReference ?? null,
  );
  const data = useFragment(
    graphql`
      fragment threePostMagSectionTopWidgetArticles on QueueItemsEdge @relay(plural: true) {
        node {
          ... on Content {
            ...postMagazineSectionWidgetThreeContent
          }
        }
      }
    `,
    reference_,
  );

  const topStories = data ?? [];
  if (topStories.length === 0) return null;

  return (
    <Container className={className}>
      <Wrapper>
        <Title>{section.name}</Title>
        <Description>{section.description?.text}</Description>
        <TopStories>
          {topStories.slice(0, 3).map(({ node }, index) => (
            <ContentItemPostMagazineSectionWidgetThree
              key={index}
              reference={node}
              topicLinkVariant={{ type: "main" }}
            />
          ))}
        </TopStories>
      </Wrapper>
    </Container>
  );
};

SectionTopWidgetThree.displayName = "SectionTopWidgetThree";

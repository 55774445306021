import { notEmpty, theme } from "@product/scmp-sdk";
import { Fragment, type FunctionComponent } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { graphql, useFragment } from "react-relay";

import { AdSlot } from "scmp-app/components/advertisement/ad-slots/ad-slot";
import { useTopBannerAdSlot } from "scmp-app/components/advertisement/ad-slots/top-banner-ad-slot/hooks";
import { TargetingPaidType } from "scmp-app/components/advertisement/ad-slots/types";
import { AppFooter } from "scmp-app/components/app-footer";
import { BaseLinkContextProvider } from "scmp-app/components/common/base-link/context";
import { ClientSideSuspense } from "scmp-app/components/common/client-side-suspense";
import { DefaultSectionClientQueryLoader } from "scmp-app/components/section/client-query-loader";
import { useSectionContext } from "scmp-app/components/section/contexts";
import { SectionNewsletterWidget } from "scmp-app/components/section/section-newsletter-widget";
import { SectionSpotlight } from "scmp-app/components/section/section-spotlight";
import { SectionTopWidgetOne } from "scmp-app/components/section/section-top/variant/one";
import { SectionTopWidgetTwo } from "scmp-app/components/section/section-top/variant/two";
import { SubscriptionWidget } from "scmp-app/components/subscription-widget";
import { SubsectionMenu } from "scmp-app/components/subsection-menu";
import { section as sectionData } from "scmp-app/data/section";
import { useGetEntityIdsByQueueItems } from "scmp-app/lib/hooks";
import { useCurrentPageType } from "scmp-app/lib/router/hooks";
import type { defaultSectionQuery$key } from "scmp-app/queries/__generated__/defaultSectionQuery.graphql";

import { getSectionWidgets } from "./helpers";
import { useRenderAdsAndSectionWidgets } from "./hooks";
import {
  Container,
  ContentContainer,
  ContentWrapper,
  Header,
  HeroArticles,
  InHouseAdsAndNewsletter1,
  InHouseAdsAndNewsletter2,
  Newsletter,
  Spotlight,
  StyledEntityOnelineMenu,
  StyledTopStoriesBlock,
  Subsection1,
  Subsection2,
  Subsection3,
  Subsection4,
  Subsection5,
  Subsection6,
  SubsectionRest,
  TopStories1,
  TopStories2,
  TopStories3,
  TopStories4,
} from "./styles";
import type { HeaderVariant } from "./types";

const headerVariantStyle: Record<string, HeaderVariant> = {
  [sectionData.thisWeekInAsia.entityId as string]: "twia" as const,
};

export type Props = {
  reference: defaultSectionQuery$key;
};

export const DefaultSection: FunctionComponent<Props> = ({ reference: reference_ }) => {
  const data = useFragment(
    graphql`
      fragment defaultSectionQuery on Query
      @argumentDefinitions(
        applicationId: { type: "String!" }
        articlesQueueName: { type: "String!" }
        commentHarrySectionIds: { type: "[String]" }
        commentQueueName: { type: "String!" }
        commentQueueLimit: { type: "Int!" }
        dailyPulseTypeUuId: { type: "String!" }
        entityId: { type: "String!" }
        highlightQueueName: { type: "String!" }
        mostPopularQueueName: { type: "String!" }
        multimediaQueueName: { type: "String!" }
        plusNewsAgendaEndDate: { type: "Timestamp!" }
        plusNewsAgendaStartDate: { type: "Timestamp!" }
        plusNewsAgendaTypes: { type: "[String]!" }
        scmpPlusPaywallTypeIds: { type: "[String]", defaultValue: [] }
        sectionSpotlightQueueName: { type: "String!" }
        trendingTopicsQueueName: { type: "String!" }
        # Include widget features
        includeAsia: { type: "Boolean!" }
        includeComment: { type: "Boolean!" }
        includeMostPopular: { type: "Boolean!" }
        includeMultimedia: { type: "Boolean!" }
        includePlus: { type: "Boolean!" }
        includeThisWeekInAsia: { type: "Boolean!" }
        includeTrendingTopics: { type: "Boolean!" }
      ) {
        section: section(filter: { entityId: $entityId }) {
          name
          entityId
          advertZone(version: 2)
          subSections {
            items {
              edges {
                ...entityOnelineMenuQueueItemsEdge
              }
            }
          }
        }
        topStories: queue(filter: { name: $articlesQueueName }) {
          items(first: 17) {
            edges {
              ...oneSectionTopWidgetQueue
              ...topStoriesBlockQueueItemsEdge
              ...contentUtilsGetEntityIdsByQueueItemQueueItemsEdge
            }
          }
        }
        spotlightQueue: queue(filter: { name: $sectionSpotlightQueueName }) {
          items(first: 4) {
            edges {
              ...contentUtilsGetEntityIdsByQueueItemQueueItemsEdge
            }
          }
        }
        commentQueue: queue(filter: { name: $commentQueueName }) {
          items(first: $commentQueueLimit) {
            edges {
              ...contentUtilsGetEntityIdsByQueueItemQueueItemsEdge
            }
          }
        }
        asiaQueue: queue(filter: { name: "section_top_3" }) @include(if: $includeAsia) {
          items(first: 5) {
            edges {
              ...contentUtilsGetEntityIdsByQueueItemQueueItemsEdge
            }
          }
        }
        twiaQueue: queue(filter: { name: "section_top_323045" })
          @include(if: $includeThisWeekInAsia) {
          items(first: 5) {
            edges {
              ...contentUtilsGetEntityIdsByQueueItemQueueItemsEdge
            }
          }
        }
        ...sectionNewsletterWidgetQuery @arguments(entityId: $entityId)
        ...sectionSpotlightQuery @arguments(sectionSpotlightQueueName: $sectionSpotlightQueueName)
        ...hooksSectionWidgetQuery
          @arguments(
            applicationId: $applicationId
            commentHarrySectionIds: $commentHarrySectionIds
            commentQueueName: $commentQueueName
            commentQueueLimit: $commentQueueLimit
            dailyPulseTypeUuId: $dailyPulseTypeUuId
            highlightQueueName: $highlightQueueName
            mostPopularQueueName: $mostPopularQueueName
            multimediaQueueName: $multimediaQueueName
            plusNewsAgendaEndDate: $plusNewsAgendaEndDate
            plusNewsAgendaStartDate: $plusNewsAgendaStartDate
            plusNewsAgendaTypes: $plusNewsAgendaTypes
            scmpPlusPaywallTypeIds: $scmpPlusPaywallTypeIds
            sectionId: $entityId
            trendingTopicsQueueName: $trendingTopicsQueueName
            # Include widget features
            includeAsia: $includeAsia
            includeComment: $includeComment
            includeMostPopular: $includeMostPopular
            includeMultimedia: $includeMultimedia
            includePlus: $includePlus
            includeThisWeekInAsia: $includeThisWeekInAsia
            includeTrendingTopics: $includeTrendingTopics
          )
      }
    `,
    reference_,
  );

  const sectionWidgets = getSectionWidgets(data.section.entityId);
  const ssrContentEntityIds = useGetEntityIdsByQueueItems([
    ...(data.topStories?.items?.edges ?? []),
    ...(data.spotlightQueue?.items?.edges ?? []),
    ...(data.commentQueue?.items?.edges ?? []),
    ...(sectionWidgets.includes("asia") ? data.asiaQueue?.items?.edges ?? [] : []),
    ...(sectionWidgets.includes("this-week-in-asia") ? data.twiaQueue?.items?.edges ?? [] : []),
  ]);

  const { advertisement: baseAdvertisement } = useSectionContext();
  const currentPageType = useCurrentPageType();

  const topStoriesComponents = [TopStories1, TopStories2, TopStories3, TopStories4];
  const [headItem1, headItem2, headItem3, ...restItems] = (
    data.topStories?.items?.edges ?? []
  ).filter(notEmpty);
  const topStorySets = [
    restItems.slice(0, 3),
    restItems.slice(3, 7),
    restItems.slice(7, 11),
    restItems.slice(11, 14),
  ];

  const subSectionComponents = [
    Subsection1,
    Subsection2,
    Subsection3,
    Subsection4,
    Subsection5,
    Subsection6,
  ];

  const subSections = data.section.subSections?.items?.edges ?? [];

  // Calculate the length of the first 6 subSections
  const first6SubSectionsLength = subSections.slice(0, 6).length;

  // Calculate the length of the LHS module, which includes the first 6 subSections, topStorySets, and the Spotlight
  const LHSModuleLength = first6SubSectionsLength + topStorySets.length + 1;

  // Use the useRenderAdsAndSectionWidgets hook to render ads and section widgets
  const { render: renderAdsAndSectionWidgets } = useRenderAdsAndSectionWidgets(
    data,
    LHSModuleLength,
  );

  useTopBannerAdSlot({
    desktop: {
      adUnit: "d_banner1",
      sizes: [[970, 250], [728, 90], [970, 90], "fluid"],
      targeting: {
        ...baseAdvertisement.targeting,
        scsid: [data.section.entityId],
      },
      zone: baseAdvertisement.zone,
    },
    mobile: {
      adUnit: "m_banner3",
      sizes: [
        [300, 100],
        [320, 100],
        [300, 50],
        [320, 50],
      ],
      targeting: {
        ...baseAdvertisement.targeting,
        scsid: [data.section.entityId],
      },
      zone: baseAdvertisement.zone,
    },
  });

  return (
    <Container>
      <ContentContainer>
        <ContentWrapper $sectionId={data.section.entityId}>
          {renderAdsAndSectionWidgets()}
          <Header $variant={headerVariantStyle[data.section.entityId]}>
            <h1>{data.section.name}</h1>
            <BaseLinkContextProvider
              customQueryParameters={{
                module: `sub_section_menu`,
                pgtype: currentPageType,
              }}
            >
              <StyledEntityOnelineMenu reference={data.section.subSections?.items?.edges ?? []} />
            </BaseLinkContextProvider>
          </Header>

          <BaseLinkContextProvider
            customQueryParameters={{
              module: "top_story",
              pgtype: "section",
            }}
          >
            <HeroArticles>
              {headItem1 && headItem2 && headItem3 && (
                <SectionTopWidgetOne
                  reference={[headItem1, headItem2, headItem3]}
                  primaryWithSection
                  onClick={() => {
                    // TODO: tracking
                  }}
                />
              )}
            </HeroArticles>

            {topStorySets.map((topStories, index) => {
              const TopStoriesComponent = topStoriesComponents[index];
              const isShowAds = index === 0 || index === topStorySets.length - 1;
              return (
                <TopStoriesComponent key={index}>
                  <StyledTopStoriesBlock
                    adZone={data?.section?.advertZone ?? "default"}
                    isShowAds={isShowAds}
                    reference={topStories}
                    slots={{
                      LastItemAd: (
                        <>
                          <AdSlot
                            adUnit={index === 0 ? "d_native2a" : "d_native2c"}
                            breakpoint={theme.breakpoints.up("tablet")}
                            sizes={[[1, 1], "fluid"]}
                            targeting={{
                              paid: TargetingPaidType.Free,
                              scsid: [data.section.entityId],
                            }}
                            zone={data?.section?.advertZone ?? "default"}
                          />
                          <AdSlot
                            adUnit="m_native2a"
                            breakpoint={theme.breakpoints.only("mobile")}
                            sizes={[[1, 1], "fluid"]}
                            targeting={{
                              paid: TargetingPaidType.Free,
                              scsid: [data.section.entityId],
                            }}
                            zone={data?.section?.advertZone ?? "default"}
                          />
                        </>
                      ),
                    }}
                    onClick={() => {
                      // TODO: tracking
                    }}
                  />
                </TopStoriesComponent>
              );
            })}
          </BaseLinkContextProvider>

          <Spotlight>
            <BaseLinkContextProvider
              customQueryParameters={{
                module: "spotlight",
                pgtype: "section",
              }}
            >
              <SectionSpotlight reference={data} />
            </BaseLinkContextProvider>
          </Spotlight>

          <ErrorBoundary fallback={<AppFooter variant="section" />}>
            <ClientSideSuspense>
              <DefaultSectionClientQueryLoader
                sectionEntityId={data.section.entityId}
                ssrContentEntityIds={ssrContentEntityIds}
              >
                {({ data }) => {
                  const subSections = data.section.subSections?.items?.edges ?? [];
                  const first6SubSections = subSections.slice(0, 6);
                  const restSubSections = subSections.slice(6);
                  return (
                    <>
                      <Newsletter>
                        <SectionNewsletterWidget reference={data} />
                      </Newsletter>
                      <InHouseAdsAndNewsletter1>
                        <BaseLinkContextProvider
                          customQueryParameters={{
                            module: "sub_msg_1",
                            pgtype: "section",
                          }}
                        >
                          <SubscriptionWidget
                            onClick={() => {
                              // TODO: tracking
                            }}
                          />
                        </BaseLinkContextProvider>
                      </InHouseAdsAndNewsletter1>
                      <InHouseAdsAndNewsletter2>
                        <BaseLinkContextProvider
                          customQueryParameters={{
                            module: "sub_msg_2",
                            pgtype: "section",
                          }}
                        >
                          <SubscriptionWidget
                            onClick={() => {
                              // TODO: tracking
                            }}
                          />
                        </BaseLinkContextProvider>
                      </InHouseAdsAndNewsletter2>
                      {first6SubSections.map(({ node }, index) => {
                        const SubSectionComponent = subSectionComponents[index];
                        const SectionTopComponent =
                          index % 2 === 0 ? SectionTopWidgetOne : SectionTopWidgetTwo;
                        return (
                          <SubSectionComponent key={index}>
                            <BaseLinkContextProvider
                              customQueryParameters={{
                                module: node.name ?? "",
                                pgtype: "section",
                              }}
                            >
                              <SubsectionMenu reference={node} />
                              <SectionTopComponent
                                reference={node.topStories?.items?.edges ?? []}
                                primaryWithTopic
                                onClick={() => {
                                  // TODO: tracking
                                }}
                              />
                            </BaseLinkContextProvider>
                          </SubSectionComponent>
                        );
                      })}
                      {restSubSections.length > 0 && (
                        <SubsectionRest>
                          {restSubSections.map(({ node }, index) => {
                            const SubSectionComponent = subSectionComponents[index];
                            const SectionTopComponent =
                              index % 2 === 0 ? SectionTopWidgetOne : SectionTopWidgetTwo;
                            return (
                              <SubSectionComponent key={index}>
                                <BaseLinkContextProvider
                                  customQueryParameters={{
                                    module: node.name ?? "",
                                    pgtype: "section",
                                  }}
                                >
                                  <SubsectionMenu reference={node} />
                                  <SectionTopComponent
                                    reference={node.topStories?.items?.edges ?? []}
                                    primaryWithTopic
                                    onClick={() => {
                                      // TODO: tracking
                                    }}
                                  />
                                </BaseLinkContextProvider>
                              </SubSectionComponent>
                            );
                          })}
                        </SubsectionRest>
                      )}
                    </>
                  );
                }}
              </DefaultSectionClientQueryLoader>
            </ClientSideSuspense>
          </ErrorBoundary>
        </ContentWrapper>
      </ContentContainer>

      <AppFooter variant="section" />
    </Container>
  );
};

DefaultSection.displayName = "DefaultSection";

import { NoSsr } from "@mui/base";
import { theme } from "@product/scmp-sdk";
import { type FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { AdSlot } from "scmp-app/components/advertisement/ad-slots/ad-slot";
import { useTopBannerAdSlot } from "scmp-app/components/advertisement/ad-slots/top-banner-ad-slot/hooks";
import { TargetingPaidType } from "scmp-app/components/advertisement/ad-slots/types";
import { useResponsiveAdSlot } from "scmp-app/components/advertisement/hooks";
import type { AppBarConfiguration } from "scmp-app/components/app-bar/types";
import { ClientSideSuspense } from "scmp-app/components/common/client-side-suspense";
import { PostMagazineHeader } from "scmp-app/components/post-magazine/header";
import { PostMagazineLeadStory } from "scmp-app/components/post-magazine/lead-story";
import { PostMagazineRecommended } from "scmp-app/components/post-magazine/recommended";
import { PostMagazineSectionTop } from "scmp-app/components/post-magazine/section-top";
import { useFetchAiArticleWidgetArticles } from "scmp-app/components/section/ai-article-widget/hooks";
import { useSectionContext } from "scmp-app/components/section/contexts";
import { section as sectionData } from "scmp-app/data";
import { AiEngineDataType } from "scmp-app/lib/ai-engine/enums";
import type { sectionPostMagazineQuery$key } from "scmp-app/queries/__generated__/sectionPostMagazineQuery.graphql";

import {
  AdContainer,
  Container,
  Header,
  LeadStory,
  Recommended,
  StyledAdSlotContainer,
  ThirdAds,
  Wrapper,
} from "./styles";

export type Props = {
  appBarConfiguration?: AppBarConfiguration;
  className?: string;
  reference: sectionPostMagazineQuery$key;
};

export const SectionPostMag: FunctionComponent<Props> = ({
  appBarConfiguration,
  className,
  reference: reference_,
}) => {
  const data = useFragment(
    graphql`
      fragment sectionPostMagazineQuery on Query
      @argumentDefinitions(articlesQueueName: { type: "String!" }, entityId: { type: "String!" }) {
        ...headerPostMagazineQuery
        ...sectionTopPostMagazineQuery
        ...leadStoryPostMagazineQuery @arguments(articlesQueueName: $articlesQueueName)
        section: section(filter: { entityId: $entityId }) {
          entityId
          advertZone(version: 2)
        }
      }
    `,
    reference_,
  );

  const { advertisement: baseAdvertisement } = useSectionContext();
  const { bindAdSlotContainer, responsiveAdSlot } = useResponsiveAdSlot(appBarConfiguration);
  const { aiData } = useFetchAiArticleWidgetArticles(AiEngineDataType.Section, [
    sectionData.postMagazine.entityId,
  ]);
  const sectionId = data.section.entityId;
  const adZone = data?.section?.advertZone ?? "default";

  useTopBannerAdSlot({
    desktop: {
      adUnit: "d_banner1",
      sizes: [[970, 250], [728, 90], [970, 90], "fluid"],
      targeting: {
        ...baseAdvertisement.targeting,
        scsid: [sectionId],
      },
      zone: baseAdvertisement.zone,
    },
    mobile: {
      adUnit: "m_banner3",
      sizes: [
        [300, 100],
        [320, 100],
        [300, 50],
        [320, 50],
      ],
      targeting: {
        ...baseAdvertisement.targeting,
        scsid: [sectionId],
      },
      zone: baseAdvertisement.zone,
    },
  });

  return (
    <Container className={className}>
      <Wrapper>
        <StyledAdSlotContainer {...bindAdSlotContainer}>
          <NoSsr>{responsiveAdSlot}</NoSsr>
        </StyledAdSlotContainer>
        <Header>
          <PostMagazineHeader reference={data} />
        </Header>
        <LeadStory>
          <PostMagazineLeadStory adZone={adZone} reference={data} sectionId={sectionId} />
        </LeadStory>
        <Recommended>
          <ClientSideSuspense>
            {aiData && aiData?.results?.length > 0 ? (
              <PostMagazineRecommended aiData={aiData} />
            ) : null}
          </ClientSideSuspense>
        </Recommended>
        <PostMagazineSectionTop adZone={adZone} reference={data} sectionId={sectionId} />

        <ThirdAds>
          <AdContainer>
            <AdSlot
              adUnit="d_banner2"
              breakpoint={theme.breakpoints.up("tablet")}
              sizes={[[970, 250], [728, 90], [970, 90], "fluid"]}
              targeting={{
                paid: TargetingPaidType.Free,
                scsid: [sectionId],
              }}
              zone={adZone}
              withBackground
              withLabel
            />
            <AdSlot
              adUnit="m_lrec2"
              breakpoint={theme.breakpoints.only("mobile")}
              sizes={[[300, 50], [320, 50], [300, 100], [320, 100], [300, 250], "fluid"]}
              targeting={{
                paid: TargetingPaidType.Free,
                scsid: [sectionId],
              }}
              zone={adZone}
              withBackground
              withLabel
            />
          </AdContainer>
        </ThirdAds>
      </Wrapper>
    </Container>
  );
};

SectionPostMag.displayName = "SectionPostMag";

import styled from "@emotion/styled";
import { fontIndivisibleVariable } from "@product/scmp-sdk";
import { SwiperSlide } from "swiper/react";

import { Swiper } from "scmp-app/components/swiper";

export const Container = styled.div``;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;

  margin-block-end: 40px;
  padding-block-start: 40px;
  padding-inline: 20px;
  border-block-start: 1px solid #777777;

  ${props => props.theme.breakpoints.up("tablet")} {
    margin-inline: 40px;
    padding-inline: 0;
  }
`;

export const Title = styled.div`
  color: #111111;
  font-weight: 300;
  font-size: 32px;
  font-family: ${fontIndivisibleVariable};
  line-height: 110%;
  letter-spacing: -1.6px;
  text-transform: uppercase;
  font-variation-settings: "wght" 300;

  ${props => props.theme.breakpoints.up("tablet")} {
    font-size: 48px;
  }

  ${props => props.theme.breakpoints.up("homeDesktop")} {
    font-size: 72px;
    letter-spacing: -3.6px;
  }
`;

export const Description = styled.div`
  display: none;

  ${props => props.theme.breakpoints.up("tablet")} {
    display: block;

    inline-size: 100%;
    max-inline-size: 300px;
    padding-inline-start: 24px;

    color: #111111;
    font-weight: 500;
    font-size: 16px;
    font-family: ${fontIndivisibleVariable};
    line-height: 26px;
    letter-spacing: -0.32px;
    text-align: end;
    font-variant-numeric: lining-nums proportional-nums;
    font-variation-settings: "wght" 500;

    border-inline-start: 1px solid #777777;
  }

  ${props => props.theme.breakpoints.up("homeDesktop")} {
    max-inline-size: 420px;

    font-size: 18px;
    letter-spacing: -0.36px;
  }
`;

export const StyledSwiper = styled(Swiper)`
  &.swiper {
    .swiper-pagination {
      inset-block-end: 0;

      padding-block-end: 21px;
      ${props => props.theme.breakpoints.up("tablet")} {
        padding-block-end: 40px;
      }
      ${props => props.theme.breakpoints.up("homeDesktop")} {
        padding-block-end: 42px;
      }
    }

    .swiper-pagination-bullet {
      inline-size: 7px;
      block-size: 7px;
      margin-inline-start: 0 !important;

      background: #ffffff;

      opacity: 0.6;

      &:not(:last-child) {
        margin-inline-end: 10px;
      }

      ${props => props.theme.breakpoints.up("tablet")} {
        inline-size: 12px;
        block-size: 12px;
      }
    }

    .swiper-pagination-bullet-active {
      opacity: 1;
    }
  }
`;

export const StyledSwiperSlide = styled(SwiperSlide)``;

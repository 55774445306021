import type { ReactNode } from "react";
import { forwardRef } from "react";
import { graphql, useFragment } from "react-relay";

import { useContentItemProviders } from "scmp-app/components/content/content-item-render/hooks";
import type { ContentItemRenderVariantProps } from "scmp-app/components/content/content-item-render/types";
import type { postMagazineLeadContentItemContent$key } from "scmp-app/queries/__generated__/postMagazineLeadContentItemContent.graphql";

import {
  AdSlot,
  Container,
  ContentContainer,
  CoverImage,
  Headline,
  StyledCoverEntityLink,
  Summary,
  Topic,
  Wrapper,
} from "./styles";

export type Props = {
  children?: ReactNode;
  reference: postMagazineLeadContentItemContent$key;
} & ContentItemRenderVariantProps;

export const ContentItemMagazinesPostMagLead = forwardRef<HTMLDivElement, Props>(
  ({ children, className, onClick, reference, topicLinkVariant }, elementReference) => {
    const content = useFragment(
      graphql`
        fragment postMagazineLeadContentItemContent on Content {
          entityId
          urlAlias
          ...entityLink
          ...hooksContentItemProvidersContent
            @arguments(
              withCoverImage: true
              withCoverImageLandscape250x99: true
              withCoverImageSize768x768: true
              withHeadline: true
              withTopicLink: true
              withSummary: true
            )
        }
      `,
      reference,
    );
    const providers = useContentItemProviders(content, topicLinkVariant);
    const topic = providers.topicLink?.();
    const headline = providers.headline({ hideTooltip: true, preferSocialHeadline: true });
    const summary = providers.summary();

    return (
      <Container className={className} ref={elementReference}>
        <StyledCoverEntityLink
          reference={content}
          onClick={() => onClick?.(content.entityId, content.urlAlias)}
        >
          <CoverImage>
            {providers.coverImage({
              responsiveVariants: {
                desktopUp: "landscape250x99",
                mobileUp: "size768x768",
                tabletUp: "landscape250x99",
              },
            })}
          </CoverImage>
        </StyledCoverEntityLink>
        <Wrapper>
          <ContentContainer>
            <Topic>{topic}</Topic>
            <Headline>{headline}</Headline>
            <Summary>{summary}</Summary>
          </ContentContainer>

          <AdSlot>{children}</AdSlot>
        </Wrapper>
      </Container>
    );
  },
);

ContentItemMagazinesPostMagLead.displayName = "ContentItemMagazinesPostMagLead";
